<template>
  <section>
    <div class="pagesing">
      <div v-if="avaible===1" class="not-avaible">
        Not Avaible Fake ID
      </div>
    </div>
  </section>
</template>

<script>
import {mapState, mapActions } from 'vuex'
import router from '@/router'
export default {
  name: "PID",
  data() {
    return {
      token:null,
      msisdn:null,
      avaible: 0
    };
  },
  mounted() {
    this.msisdn = this.$route.params.cmsisdn;
    this.$store.state.pid = this.msisdn;
    this.$store.state.user = this.msisdn;
    this.token = btoa(this.msisdn);
    this.$store.state.token = this.token;
    this.avaible=0;
      // if(this.user==null && this.pid==null){
      //     this.$store.state.pid = this.$route.params.cmsisdn
      //     this.goTo('login')
      // }else{
      //     this.goTo('home')
      // }
      // this.setStore();
      this.getSubscriptionStatusByFakeId().then(() => {
        // this.setStore();
        // this.telco='smart';
        this.$router.push({ name: "loading" });
        
      }, error => {
          console.error('err', error);
          this.avaible=1;
      });
  },
  computed: {
  ...mapState([
    'pid',
    'user',
    'telco'
  ]),
  
},
  methods: {
    goTo(page) {
      router.push({ name: page });
    },
    ...mapActions([
    'getSubscriptionStatusByFakeId',
    'getGames',
    ]),
    setStore() {
      this.$store.commit("SET_TOKEN", this.token);
      this.$store.commit("SET_USER", this.msisdn);
    },
  }
};
</script>

<style scoped>
.pagesing{
  background-color: #363331;
  position: fixed;
  width: 100vw;
  height: 100vh;
}
.not-avaible{
  color: #fff;
  width: 80vw;
  height: 80vh;        
  position: absolute;
  top: 50vh;
  left: 50%;
  margin: -40vh 0 0 -40vw;
}
</style>

<template>
  <section>

  </section>
</template>

<script>
import {mapState } from 'vuex'
import router from '@/router'
export default {
  name: "PID",
  data() {
    return {
      token:null,
      msisdn:null,
    };
  },
  mounted() {
    this.$store.state.user = null;
    this.$store.state.android = true;
    this.goTo('login')
  },
  computed: {
  ...mapState([
    'user',
    'android',
  ]),
  
},
  methods: {
    goTo(page) {
      router.push({ name: page });
    },
  }
};
</script>

<style scoped>
</style>

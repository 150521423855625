import { render, staticRenderFns } from "./Pid.vue?vue&type=template&id=189ab31b&scoped=true&"
import script from "./Pid.vue?vue&type=script&lang=js&"
export * from "./Pid.vue?vue&type=script&lang=js&"
import style0 from "./Pid.vue?vue&type=style&index=0&id=189ab31b&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "189ab31b",
  null
  
)

export default component.exports